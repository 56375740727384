:root {
  /* COLOR VARIABLES */
  --background: color-mix(in srgb, var(--accent) 2%, #1D1E28 98%);
  --accent-contrast-color: black;
  --color: white;
  --border-color: rgba(255, 255, 255, .1);
  --article-link-color: var(inherit);
}

// Native CSS Variable aren't supported in a combination with MEDIA QUERIES. Wait for a new standard https://drafts.csswg.org/css-env-1/ 
// --phone: "max-width: 684px";
// --tablet: "max-width: 900px";

$phone: 684px;
$tablet: 900px;